import { Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTokenClaims } from '../security/useTokenClaims'
import { getForemen } from '../services/backlogService'
import { Employee } from '../types/Employee/Employee'
import { ShiftEndSelectForeman } from '../components/reports/shift-end/ShiftEndSelectForeman'

const ShiftEndSelectForemanSelectionPage = () => {
	const { id } = useTokenClaims()
	const [foremen, setForemen] = useState<Employee[]>()

	const loadData = async () => {
		const found = await getForemen(true, id)
		setForemen(found)
	}
	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
				<Typography
					sx={{
						fontSize: '1.5rem',
						paddingLeft: '1rem',
						paddingTop: '.5rem',
						outline: '1px solid #DDDDDD',
						zIndex: '3',
					}}
				>
					Foreman
				</Typography>
				<Box sx={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
					<ShiftEndSelectForeman foremen={foremen} />
				</Box>
			</Box>
		</>
	)
}

export default ShiftEndSelectForemanSelectionPage
