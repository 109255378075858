import { Chip, TableRow } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Employee } from '../../../types/Employee/Employee'
import { getTimeSheetsForSupervisor } from '../../../services/shiftEndReportService'
import { StyledTableCell } from '../../core/StyledTableCell'

type ForemanRowProps = {
	foreman: Employee
}

export function ShiftEndForemanRow({ foreman }: ForemanRowProps) {
	const navigate = useNavigate()

	return (
		<>
			<TableRow
				onClick={() => navigate(`${foreman.id}`)}
				key={foreman.id}
				sx={{ cursor: 'pointer' }}
			>
				<StyledTableCell align='left'>
					{foreman.firstName} {foreman.lastName}
				</StyledTableCell>
				<StyledTableCell align='center'>{foreman.employeeId}</StyledTableCell>
				<StyledTableCell align='center'>{foreman.primaryCraft}</StyledTableCell>
				<StyledTableCell align='center'>{foreman.classification}</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip label={'Online'} variant={'Online'} />
				</StyledTableCell>
				<StyledTableCell align='center'>
					<Chip
						label={foreman.hasUnconfirmedTimeSheets ? 'Not Submitted' : 'Submitted'}
						variant={foreman.hasUnconfirmedTimeSheets ? 'failure' : 'success'}
					></Chip>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
