import * as React from 'react'
import logo from '../../assets/images/logo-header.png' // relative path to image
import { AppBar, Toolbar, Typography } from '@mui/material'
import './BrockAppBar.scss'
import { useTokenClaims } from '../../security/useTokenClaims'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

type BrockAppBarProps = {
	showAccountInfo: boolean
}

export default function BrockAppBar({ showAccountInfo = true }: BrockAppBarProps) {
	const { firstName, lastName } = useTokenClaims()
	return (
		<AppBar
			position='fixed'
			sx={{
				backgroundColor: (theme) => theme.palette.primary.main,
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
		>
			<Toolbar sx={{ justifyContent: 'space-between' }}>
				<Typography variant='h6' noWrap component='div'>
					<img src={logo} alt={'Brock Logo'} height={50} />
				</Typography>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography variant='h1' noWrap component='h1'>
						Field Management Tool v2.0
					</Typography>
					<Typography variant='h3' noWrap component='h3'>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{showAccountInfo && (
								<>
									<div>
										{firstName} {lastName.substring(0, 1)}
									</div>
									<div style={{ paddingLeft: '5px' }}>
										<AccountCircleIcon />
									</div>
								</>
							)}
						</div>
					</Typography>
				</div>
			</Toolbar>
		</AppBar>
	)
}
