import * as React from 'react'
import { useEffect } from 'react'
import { ProjectInfo } from '../types/Project/ProjectInfo'
import './styles.scss'

import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material'
import Breadcrumbs from '../components/navigation/Breadcrumbs'
import { DatePicker } from '@mui/x-date-pickers'
import { useShiftEndContext } from '../components/reports/shift-end/ShiftEndContext'

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const TimeTabs = ({ project }: { project: ProjectInfo | undefined }) => {
	const [value, setValue] = React.useState(0)

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (!location.pathname.includes('workers') && !location.pathname.includes('workitems')) {
			navigate('workers')
		}
		// if on files tab and click menu for project it should go back to config tab, this detects nav change and sets the tab flag accordingly
		location.pathname.includes('workitems') ? setValue(1) : setValue(0)
	}, [location])

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	return (
		// MuiButtonBase-root-JvZdr clbaxI MuiButtonBase-root MuiTab-root-beuHPf eEmZwq MuiTab-root MuiTab-textColorPrimary Mui-selected
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
					<Tab
						color='#ff000'
						label='Worker Summary'
						sx={(theme) => ({
							color: theme.palette.info.main,
							'&:selected': theme.palette.info.main,
						})}
						{...a11yProps(0)}
						onClick={() => navigate('workers')}
					/>
					<Tab
						color='info'
						label='Work Items Summary'
						sx={(theme) => ({
							color: theme.palette.info.main,
							'&:selected': theme.palette.info.main,
						})}
						{...a11yProps(1)}
						onClick={() => navigate('workitems')}
					/>
				</Tabs>
			</Box>
			<Outlet context={project} />
		</Box>
	)
}

const ShiftEndReportPage = () => {
	const { shiftDate, setShiftDate } = useShiftEndContext()

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
				<Box>
					<Breadcrumbs />
					<Typography
						sx={{
							fontSize: '1.5rem',
							paddingLeft: '1rem',
							paddingTop: '.5rem',
							zIndex: '3',
						}}
					>
						Shift-End Report
					</Typography>
				</Box>
				<Box sx={{ marginRight: '1rem' }}>
					<DatePicker
						label='Pick a date'
						value={shiftDate}
						onChange={(value) => {
							value && setShiftDate(value)
						}}
						renderInput={(props) => <TextField {...props} />}
					/>
				</Box>
			</Box>
			<TimeTabs project={undefined} />
		</>
	)
}

export default ShiftEndReportPage
