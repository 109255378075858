import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/inter'
import CssBaseline from '@mui/material/CssBaseline'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './security/authConfig'
import { ThemeProvider } from '@mui/material'
import { getDefaultTheme } from './themes'
import { BacklogProvider } from './components/backlog/BacklogContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AssignWorkProvider } from './components/assignwork/AssignWorkContext'

const msalInstance = new PublicClientApplication(msalConfig())
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// <React.StrictMode>
// was removed because cause Mount/Unmount/Mount on initial load of components
// meaning two fires ALWAYS of useEffect causing double data loads
root.render(
	<MsalProvider instance={msalInstance}>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={getDefaultTheme()}>
				<CssBaseline />
				<BrowserRouter>
					<AssignWorkProvider>
						<BacklogProvider>
							<App />
						</BacklogProvider>
					</AssignWorkProvider>
				</BrowserRouter>
			</ThemeProvider>
		</LocalizationProvider>
	</MsalProvider>,
)

reportWebVitals()
