import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
// import Home from '../../pages/Home'
import NotAuthorized from '../../pages/NotAuthorized'
import { useTokenClaims } from '../../security/useTokenClaims'
import LoggedInLayout from '../layout/LoggedInLayout'

const ProjectsListPage = React.lazy(() => import('../../pages/ProjectsListPage'))

export default function BrockLimitedRoutes() {
	const { role } = useTokenClaims()
	return (
		<Routes>
			<Route path='/' element={<LoggedInLayout showDrawer={false} />}>
				<Route
					index
					element={
						<React.Suspense fallback={<>...</>}>
							<ProjectsListPage showCrumb={false} />
						</React.Suspense>
					}
				/>
				<Route path='*' element={<NotAuthorized />} />
			</Route>
		</Routes>
	)
}
