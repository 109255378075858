import dayjs from 'dayjs'
import { Employee } from '../types/Employee/Employee'
import { Constraint } from '../types/Work/Constraint'
import { PaginatedWorkItems, WorkItem } from '../types/Work/WorkItem'
import { getAll, getOne, post, put, deleteOne, patch, postWithResponse } from './httpService'
import { ImportResponse } from '../types/Upload/ImportResponse'
import { BlockBlobClient, BlockBlobParallelUploadOptions } from '@azure/storage-blob'
import { ConstraintAttachment } from '../types/Work/ConstraintAttachment'

const getEmployeeWorkItems = async (
	includeDirectReports?: boolean,
	id?: number,
): Promise<{ employee: Employee; directReports: Employee[] }> => {
	const result = await getAll<{ employee: Employee; directReports: Employee[] }>(
		`/Workers/${id}/WorkItems?direcReportWorkItems=${includeDirectReports}`,
		'Failed to get employee work items.',
	)

	return {
		employee: result?.data?.employee,
		directReports: result?.data?.directReports ?? [],
	}
}

const getUnassignedWorkItems = async (): Promise<WorkItem[]> => {
	const result = await getAll<WorkItem[]>(
		'/Workitems?status=Unassigned',
		'Failed to get unassigned work items.',
	)
	return result.data ?? []
}

const assignWorkItem = async (workItemId: number, toId: number, fromId: number): Promise<boolean> => {
	try {
		const result = await post(`/Workers/${toId}/${fromId}/WorkItems/${workItemId}`, {})
		return result.success
	} catch {
		throw {
			message: `Could not assign workItem ${workItemId} to ${toId}`,
		}
	}
}

const unassignWorkItem = async (workerId: number, workItemId: number, fromEmployeeId:number): Promise<boolean> => {
	const result = await deleteOne(`/Workers/${workerId}/WorkItems/${workItemId}/From/${fromEmployeeId}`)
	return result.success
}

type AddConstraintRequest = {
	comment: string
	hasAttachment?: boolean
	attachmentMimeType?: string
}
const addConstraint = async (
	workItemId: number,
	constraintComment: string,
	constraintAttachment: Blob | null,
	attachmentMimeType?: string,
): Promise<boolean> => {
	try {
		// no constraint attachemnt
		const rq: AddConstraintRequest = {
			comment: constraintComment,
			hasAttachment: constraintAttachment ? true : false,
			attachmentMimeType,
		}

		const addConstraintResponse = await postWithResponse<ImportResponse>(
			`/WorkItems/${workItemId}/Constraints`,
			rq,
		)

		if (
			addConstraintResponse.data.uploadUri &&
			addConstraintResponse.data.sasToken &&
			constraintAttachment
		) {
			const blockBlobClient = new BlockBlobClient(
				`${addConstraintResponse.data.uploadUri}${addConstraintResponse.data.sasToken}`,
			)

			// set mimetype as determined from browser with file upload control
			const options: BlockBlobParallelUploadOptions = {
				blobHTTPHeaders: { blobContentType: constraintAttachment.type },
			}

			//upload file
			await blockBlobClient.uploadData(constraintAttachment, options)
		}
		return true
	} catch (error) {
		throw {
			message: 'Could not add constraint comment.',
		}
	}
}

const getConstraintsByWorkItemId = async (workItemId: number) => {
	const result = await getAll<Constraint[]>(
		`/Constraints?workItemId=${workItemId}`,
		'Failed to get all Constraints',
	)
	return result.data ?? []
}

const updateWorkItemProgress = async (
	workItemId: number,
	taskId: number,
	progress: number,
	isOverride = false,
) => {
	const result = isOverride
		? await put(`/WorkItems/${workItemId}/Tasks/${taskId}`, { progress })
		: await patch(`/WorkItems/${workItemId}/Tasks/${taskId}`, { progress })

	return result.success
}

const updateWorkItemData = (
	workItemId: number,
	newValue: number,
	field: 'QuantityBudgeted' | 'BudgetedHours',
) => {
	const url = `/WorkItems/${workItemId}/${field}`
	return patch(url, { [field]: newValue })
}

const getFirstLayerWorkItems = async (page: number): Promise<PaginatedWorkItems> => {
	const result = await getAll<PaginatedWorkItems>(`/WorkItems?page=${page}`, 'Failed to get First Layer Work Items')
	return result.data ?? null
}

const getSecondaryWorkItems = async (workItemId: number): Promise<WorkItem> => {
	const result = await getAll<WorkItem>(`/WorkItems/${workItemId}`, 'Failed to get Secondary Work Items')
	return result.data ?? null
}

const searchWorkItems = async (searchText: string, page: number, includeCount: boolean): Promise<PaginatedWorkItems> => {
	const result = await getAll<PaginatedWorkItems>(`/WorkItems?searchText=${searchText}&page=${page}${includeCount ? '&includeCount=true' : '&includeCount=false'}`, 'Failed to get Search results')
	return result.data ?? null
}

const getAllConstraints = async (): Promise<Constraint[]> => {
	const result = await getAll<Constraint[]>('/Constraints', 'Failed to get all Constraints')
	return result.data ?? []
}
const getConstraintAttachment = async (constraint: Constraint): Promise<ConstraintAttachment> => {
	const result = await getOne<ConstraintAttachment>(
		`/Constraints/${constraint.id}/Attachments/${constraint.attachments[0]}`,
		'Failed to get all Constraints',
	)
	return result.data ?? []
}

const clearConstraint = async (constraint: Constraint): Promise<boolean> => {
	const result = await deleteOne(`/Constraints/${constraint.id}`)
	return result.success
}

const clearAllConstraintsOfWorkItem = async (workItemId: number): Promise<boolean> => {
	const result = await deleteOne(`/Constraints/?workItemId=${workItemId}`)
	return result.success
}

const deleteWorkItem = async (workItemId: number): Promise<boolean> => {
	const result = await deleteOne(`/WorkItems/${workItemId}`)
	return result.success
}

const updateWorkItem = async (workItem: WorkItem): Promise<boolean> => {
	const result = await put('/WorkItems', {
		id: workItem.id,
		budgetedHours: workItem.budgetedHours,
		releaseDate: workItem.releaseDate,
		nocDate: workItem.nocDate,
		quantity: workItem.quantityBudgeted,
	})
	return result.success
}

const getDirectReportWorkItem = async (directReportdId: number): Promise<Employee> => {
	const result = await getOne<Employee>(`/Workers/${directReportdId}/WorkItems/DirectReport`)
	return result.data
}

export {
	getEmployeeWorkItems,
	assignWorkItem,
	getUnassignedWorkItems,
	unassignWorkItem,
	updateWorkItemProgress,
	getFirstLayerWorkItems as getAllWorkItems,
	updateWorkItemData,
	getAllConstraints,
	clearConstraint,
	addConstraint,
	getConstraintAttachment,
	deleteWorkItem,
	updateWorkItem,
	getConstraintsByWorkItemId,
	clearAllConstraintsOfWorkItem,
	getSecondaryWorkItems,
	searchWorkItems,
	getDirectReportWorkItem
}
