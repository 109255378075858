import axios from 'axios'
import { useEnvironmentVariables } from '../components/hooks/useEnvironmentVariables'
import { ErrorToastData } from '../types/Core/ErrorToastData'
import { showErrorToast } from './alertServices'
import useWpaToken from '../security/useWpaToken'
import { useMsal } from '@azure/msal-react'

async function getAll<T>(url?: string, logInfo?: string): Promise<ApiResponse<T>> {
	// use rq and url to call axios and also add headers to that that include
	// the token
	// use hook to get apiUri and append url to the api uri
	const { apiUri } = useEnvironmentVariables()
	try {
		const res = await axios.get(`${apiUri}${url}`, {
			headers: {
				'api-call': 'getAll',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true, data: res.data ? res.data as T : [] as T }
	} catch {
		throw { success: false, data: null as T }
	}
}

async function getOne<T>(url: string, logInfo?: string): Promise<ApiResponse<T>> {
	const { apiUri } = useEnvironmentVariables()
	try {
		const res = await axios.get(`${apiUri}${url}`, {
			headers: {
				'api-call': 'getOne',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true, data: res.data as T }
	} catch {
		throw { success: false, data: null as T }
	}
}

export type ApiBaseResponse = {
	success: boolean
}
export type ApiResponse<T> = ApiBaseResponse & {
	id?: number
	data: T
}

async function post(url: string, data: any, logInfo?: string): Promise<ApiBaseResponse> {
	const { apiUri } = useEnvironmentVariables()
	try {
		await axios.post(`${apiUri}${url}`, data, {
			headers: {
				'api-call': 'post',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true }
	} catch {
		throw { success: false }
	}
}

async function postWithResponse<T>(
	url: string,
	data: any,
	logInfo?: string,
): Promise<ApiResponse<T>> {
	const { apiUri } = useEnvironmentVariables()

	try {
		const res = await axios.post(`${apiUri}${url}`, data, {
			headers: {
				'api-call': 'post',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true, data: res.data as T }
	} catch {
		throw { success: false, data: null as T }
	}
}

async function put(url: string, data: any, logInfo?: string): Promise<ApiBaseResponse> {
	const { apiUri } = useEnvironmentVariables()
	try {
		const res = await axios.put(`${apiUri}${url}`, data, {
			headers: {
				'api-call': 'put',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true }
	} catch {
		throw { success: false }
	}
}

async function patch(url: string, data: any, logInfo?: string): Promise<ApiBaseResponse> {
	const { apiUri } = useEnvironmentVariables()
	try {
		const res = await axios.patch(`${apiUri}${url}`, data, {
			headers: {
				'api-call': 'patch',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true }
	} catch {
		throw { success: false }
	}
}

async function deleteOne(url: string, logInfo?: string): Promise<ApiBaseResponse> {
	const { apiUri } = useEnvironmentVariables()
	try {
		await axios.delete(`${apiUri}${url}`, {
			headers: {
				'api-call': 'post',
				'log-info': logInfo ?? '',
			},
		})
		return { success: true }
	} catch {
		throw { success: false }
	}
}

axios.interceptors.request.use(function (config) {
	// AD TOKEN handled in react component
	if (config.url) {
		let { token } = useWpaToken()
		if (
			config.url?.toLowerCase().includes('/authorize') ||
			(config.url?.toLowerCase().endsWith('/projects') && config.method?.toLowerCase() === 'get')
		) {
			// use AD Token from local storage
			token = localStorage.getItem('AdToken')
		}
		config.headers.Authorization = `Bearer ${token}`
	}

	return config
})

axios.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.config.method !== 'options') {
			const err = error.response.data as ErrorToastData
			showErrorToast({
				message: err.message,
				eventId: err.eventId ?? 'No Event Id',
				traceId: err.traceId ?? 'No Trace Id',
			})
		}
		return Promise.reject(error)
	},
)

export { getAll, getOne, put, post, postWithResponse, deleteOne, patch }
