import { Activity } from '../types/Reports/ShiftEnd/Activity'
import { ShiftEndWorkItemsSummary } from '../types/Reports/ShiftEnd/ShiftEndWorkItems'
import { TimeSheet } from '../types/Reports/ShiftEnd/TimeSheet'
import { deleteOne, getAll, patch } from './httpService'
import dayjs from 'dayjs'

const getWorkItemsForSupervisor = async (supervisorId: number, date: Date) => {
	return await getAll<ShiftEndWorkItemsSummary[]>(
		`/TimeSheets/${supervisorId}/WorkItems?Date=${date.toISOString()}`,
	)
}

const getTimeSheetsForSupervisor = async (supervisorId: number, date: Date) => {
	return await getAll<TimeSheet[]>(
		`/TimeSheets?supervisorId=${supervisorId}&Date=${date.toISOString()}`,
	)
}

const confirmTimeSheet = async (timeSheetId: number) => {
	return await patch(`/TimeSheets/${timeSheetId}/Confirm`, {})
}

const updateTimeSheetActivity = async (
	timesheetId: number,
	activityId: number,
	startTimeDate: Date,
	endTimeDate: Date,
) => {
	const startTime = dayjs(startTimeDate).toISOString()
	const endTime = dayjs(endTimeDate).toISOString()
	return await patch(`/TimeSheets/${timesheetId}/TimeRecord/${activityId}`, { startTime, endTime })
}

const updateTimeSheetActivities = async (timesheetId: number, activities: Activity[]) => {
	return await patch(
		`/TimeSheets/${timesheetId}/TimeRecords`,
		activities.map((activity) => ({
			startTime: activity.startTime,
			endTime: activity.endTime,
			id: activity.id,
		})),
	)
}

const removeUnproductiveRecord = async (timesheetId: number, timeRecordId: number) => {
	return await deleteOne(`/TimeSheets/${timesheetId}/TimeRecords/${timeRecordId}`)
}

export {
	getWorkItemsForSupervisor,
	getTimeSheetsForSupervisor,
	confirmTimeSheet,
	updateTimeSheetActivity,
	updateTimeSheetActivities,
	removeUnproductiveRecord
}
