import { TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

type TimePickerCellProps = {
	timeValue: Date | null
	onChange: (newValue: Date) => void
	canEdit: boolean
}

const TimePickerCell = ({ timeValue, onChange, canEdit }: TimePickerCellProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isDirty, setIsDirty] = useState<boolean>(false)
	const [localValue, setLocalValue] = useState<Date | null>(timeValue)
	const [isAccepted, setIsAccepted] = useState<boolean>(false)

	useEffect(() => {
		if (isAccepted) {
			if (onChange && isDirty && localValue) onChange(localValue)
			setIsDirty(false)
			setIsOpen(false)
			setIsAccepted(false)
		}
	}, [isAccepted])


	useEffect(() => {
		if (timeValue) {
		  setLocalValue(timeValue);
		}
	  }, [timeValue]);

	const handleTimeChange = (value: string) => {
		const timeParts = value.split(':')
		if (localValue) {
			const updatedTime = dayjs()
				.set('hour', parseInt(timeParts[0]))
				.set('minute', parseInt(timeParts[1]))
				.toDate()

			setLocalValue(updatedTime)
			setIsDirty(true)
		}
	}

	if (isOpen && canEdit) {
		return (
			<>
				<TextField
					label='Enter time'
					type='time'
					onChange={(e) => handleTimeChange(e.target.value)}
					onBlur={(e) => {
						const timeParts = e.target.value.split(':')
						const updatedTime = dayjs()
							.set('hour', parseInt(timeParts[0]))
							.set('minute', parseInt(timeParts[1]))
							.toDate()
						setLocalValue(updatedTime)
						setIsAccepted(true)
					}}
					value={dayjs(localValue).format('HH:mm')}
					InputLabelProps={{
						shrink: true,
					}}
					autoFocus
					inputProps={{
						step: 300, // 5 min
					}}
				/>
			</>
		)
	} else {
		return (
			<div
				onClick={() => {
					if (canEdit) setIsOpen(true)
				}}
			>
				{dayjs(localValue).utc(true).format('h:mm A')}
			</div>
		)
	}
}

export default TimePickerCell
