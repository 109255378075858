export type EnvironmentVariables = {
	apiUri: string
	redirectUri: string
	showSuccessToasts: boolean
	showErrorToasts: boolean
}

export type ExtendedWindow = Window &
	typeof globalThis & {
		API_URI: string
		REDIRECT_URI: string
		SHOW_SUCCESS_TOASTS: string
		SHOW_ERROR_TOASTS: string
	}

export const useEnvironmentVariables = (): EnvironmentVariables => {
	const checkedApiURI =
		((window as ExtendedWindow).API_URI as string) === '%SUBAPIURI%'
			? 'http://localhost:5139'
			: ((window as ExtendedWindow).API_URI as string)

	const checkedRedirectURI =
		((window as ExtendedWindow).REDIRECT_URI as string) === '%SUBREDIRECTURI%'
			? 'http://localhost:3000'
			: ((window as ExtendedWindow).REDIRECT_URI as string)

	const checkedShowSuccessToasts =
		(window as ExtendedWindow).SHOW_SUCCESS_TOASTS === '%SUBSHOWSUCCESSTOASTS%'
			? true
			: ((window as ExtendedWindow).SHOW_SUCCESS_TOASTS as string).toLowerCase() === 'true'

	const checkedShowErrorToasts =
		(window as ExtendedWindow).SHOW_ERROR_TOASTS === '%SUBSHOWERRORTOASTS%'
			? true
			: ((window as ExtendedWindow).SHOW_ERROR_TOASTS as string).toLowerCase() === 'true'

	const EnvironmentVars: EnvironmentVariables = {
		apiUri: checkedApiURI,
		redirectUri: checkedRedirectURI,
		showSuccessToasts: checkedShowSuccessToasts,
		showErrorToasts: checkedShowErrorToasts,
	}
	return EnvironmentVars
}
