import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { TimeSheet } from '../../../../types/Reports/ShiftEnd/TimeSheet'
import { StyledTableCell } from '../../../core/StyledTableCell'
import { WorkerRow } from './WorkerRow'
import React from 'react'

type WorkerGridProps = {
	timesheets: TimeSheet[] | undefined
	openDialog: (timeSheetId: number) => void
}

export function WorkerGrid({ openDialog, timesheets }: WorkerGridProps) {
	return (
		<TableContainer
			sx={{
				height: 'calc(80vh - 56px)',
				width: '100%',
				overflowX: 'scroll',
				overflowY: 'scroll',
			}}
		>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<StyledTableCell />
						<StyledTableCell align='center'>Type</StyledTableCell>
						<StyledTableCell align='left'>Title</StyledTableCell>
						<StyledTableCell align='center'>Activity</StyledTableCell>
						<StyledTableCell align='right'>Start Time</StyledTableCell>
						<StyledTableCell align='right'>End Time</StyledTableCell>
						<StyledTableCell align='right'>Hours</StyledTableCell>
						<StyledTableCell align='left'>Comments</StyledTableCell>
						<StyledTableCell align='center'>Actions</StyledTableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{/* Create a WorkerRow component for each element in timesheets array */}
					{(timesheets ?? []).map((timesheet: TimeSheet) => (
						<WorkerRow timesheet={timesheet} key={timesheet.id} openDialog={openDialog} />
					))}
					{/* Show empty state if timesheets array has no items */}
					{(timesheets?.length ?? 0) === 0 && (
						<TableRow>
							<StyledTableCell align='center' scope='row' colSpan={17}>
								<h4 style={{ fontStyle: 'italic' }}>No Time Sheets were found for this Foreman</h4>
							</StyledTableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
