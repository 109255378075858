import { Alert } from '@mui/lab'
import * as React from 'react'
import { CSSProperties } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function NotAuthorized() {

	const location = useLocation();
	if (location.pathname !== '/'){
		const navigate = useNavigate()
		console.info('Not Authorized Component Is Redirecting To "/"')
		window.location.href = '/'
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: 'calc(100% - 224px)',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div style={{ display: 'flex', fontSize: '140pt' }}>
				<div>🚫</div>
			</div>
			<div style={{ display: 'flex' }}>
				<h2>Not Authorized</h2>
			</div>
			<div style={{ display: 'flex' }}>
				<Alert severity='warning'>
					You currently do not have access ot this application. Please contact support if you find
					this in error.
				</Alert>
			</div>
		</div>
	)
}
