import { Alert } from '@mui/lab'
import * as React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
	const emojis = ['👷🏽‍♀️', '👷🏿‍♂️', '👷🏽‍♂️', '👷🏼']
	const workerEmoji = emojis[Math.floor(Math.random() * 4)]
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: 'calc(100% - 224px)',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div style={{ display: 'flex', fontSize: '140pt', fontWeight: 'bold' }}>
				<div>404</div>
				<div>{workerEmoji}</div>
			</div>
			<div style={{ display: 'flex' }}>
				<h2>Not Found</h2>
			</div>
			<div style={{ display: 'flex' }}>
				<Alert severity='info'>
					The page you attempted could not be found.
					<Link to='/'>Go to the home page</Link>
				</Alert>
			</div>
		</div>
	)
}
