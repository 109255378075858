import * as React from 'react'
import {
	Box,
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ThemeProvider,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { getSideNavBarTheme } from '../../themes'
import { ExpandLess, ExpandMore, FolderOutlined, FolderSpecialOutlined } from '@mui/icons-material'
import { useTokenClaims } from '../../security/useTokenClaims'

type ProjectNavigationDropdownProps = {
	routeInfo: any
}

const ProjectNavigationDropdown = ({ routeInfo }: ProjectNavigationDropdownProps) => {
	const [open, setOpen] = React.useState(false)

	const { projectId } = useTokenClaims()
	const handleClick = () => {
		setOpen(!open)
	}

	const navigate = useNavigate()
	return (
		<>
			{routeInfo.title === 'Projects' && (
				<>
					<ListItemButton onClick={handleClick}>
						<ListItemIcon>
							<ListItemIcon>{routeInfo.icon}</ListItemIcon>
						</ListItemIcon>
						<ListItemText primary={routeInfo.title} />
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse sx={{ marginLeft: '15px' }} in={open} timeout='auto' unmountOnExit>
						<ListItemButton onClick={() => navigate(`/projects/${projectId}/details`)}>
							<ListItemIcon>
								<FolderSpecialOutlined />
							</ListItemIcon>
							<ListItemText primary='Current Project' />
						</ListItemButton>
						<ListItemButton onClick={() => navigate('/projects')}>
							<ListItemIcon>
								<FolderOutlined />
							</ListItemIcon>
							<ListItemText primary='Assigned Projects' />
						</ListItemButton>
					</Collapse>
				</>
			)}
		</>
	)
}

export default ProjectNavigationDropdown
