import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { ChangeEvent, useState, useEffect } from 'react'
import { Clear } from '@mui/icons-material'

type SearchBarProps = {
	onChange: (searchText: string, includeAllTasks: boolean) => void
	searchPlaceholder: string
	showIncludeFilter?: boolean
	searchText?: string
}

export default function SearchBar({
	onChange,
	searchPlaceholder,
	showIncludeFilter = true,
	searchText = ""
}: SearchBarProps) {
	const [value, setValue] = useState(searchText)
	const [includeAllTasks, setIncludeAllTasks] = useState(true)

	const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value)
	}

	const handleCheckClick = (event: ChangeEvent<HTMLInputElement>) => {
		setIncludeAllTasks(event.target.checked)
	}

	const clearFilter = () => {
		setValue('')
		setIncludeAllTasks(true)
	}

	useEffect(() => {
		if (onChange) {
			onChange(value, includeAllTasks)
		}
	}, [value, includeAllTasks])

	return (
		<Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 550 }}>
			<IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
				<SearchIcon />
			</IconButton>
			<TextField
				autoComplete='off'
				value={value}
				onChange={handleChangeEvent}
				size='small'
				variant='standard'
				sx={{ ml: 1, flex: 1, width: '600px' }}
				placeholder={searchPlaceholder}
				inputProps={{ 'aria-label': 'filter work items' }}
			/>
			<IconButton
				onClick={clearFilter}
				type='button'
				sx={{ p: '10px' }}
				aria-label='clear search'
				title='Clear Search'
			>
				<Clear />
			</IconButton>
			{showIncludeFilter && (
				<>
					<Checkbox onChange={handleCheckClick} checked={includeAllTasks} title='Show All Tasks' />
					<Typography sx={{ m: 1 }}>Show All Tasks</Typography>
				</>
			)}
		</Box>
	)
}
