/* eslint-disable quotes */
import { deleteOne, getAll, getOne, patch, post, put } from './httpService'
import { useTokenClaims } from '../security/useTokenClaims'
import { Employee } from '../types/Employee/Employee'
import { showErrorToast } from './alertServices'
import { ThrownServiceError } from '../types/Core/ThrownServiceError'

const unassignWorker = async (employee: Employee) => {
	const result = await deleteOne(
		`/Workers/${employee.id}/Supervisor`,
		`Could not unassign worker ${employee.firstName} ${employee.lastName}.`,
	)
	return result.success
}

const assignWorker = async (employeeFrom: Employee, employeeTo?: Employee) => {
	try {
		if (!employeeTo)
			throw { eventId: 1, traceId: 'AW001', message: 'EmployeeTo cannot be undefined in assign' }
		if (employeeTo.id === employeeFrom.id) throw { message: 'Cannot assign to same employee' }
		if (employeeFrom.role.hierarchyIndex <= employeeTo.role.hierarchyIndex) throw { message: `Cannot assign ${employeeFrom.role.title} to ${employeeTo.role.title}` }
		const result = await post(`/Workers/${employeeTo.id}/DirectReports/${employeeFrom.id}`, {})
		return result.success
	} catch (err: any) {
		throw {
			message: err.message ? err.message : `Could not assign worker ${employeeFrom.firstName} ${employeeFrom.lastName} to ${
				employeeTo?.firstName ?? ''
			} ${employeeTo?.lastName ?? ''}`
		}
	}
}

const getAssignWorkersData = async (): Promise<{
	assignedWorkers: Employee[]
	availableWorkers: Employee[]
}> => {
	try {
		const { projectId, id, role } = useTokenClaims()
		const result = await getAll<{ hierarchyIndex: number; title: string }[]>(
			`/Projects/${projectId}/roles`,
			'Could not retrieve Project Roles.',
		)

		if (!result.success) throw { showError: false }

		const myRole = result.data.find((x) => x.title === role.title)
		if (!myRole) {
			throw { showError: true, message: 'Current Role not found in retrieved roles' }
		}
		const oneRoleBelowUser = result.data.find((x) => x.hierarchyIndex === myRole.hierarchyIndex + 1)
		if (!oneRoleBelowUser) {
			throw { showError: true, message: 'Next level role not found in retrieved roles' }
		}

		const workerPool = await getAll<Employee[]>(
			`/Workers?${
				role.title === 'ProjectControls'
					? 'includeChildren=true'
					: `reportsTo=${id}&includeChildren=true`
			}`,
			'Could not retrieve worker data for PCM.',
		)

		if (!result.success) throw { showError: false }
		// split up immediate children when not the next level below ProjectControls
		// they go into Left Side, otherwise all direct reports
		if (role.title === 'ProjectControls') {
			const leftSide = workerPool.data.filter((w: Employee) => {
				return w.role.title !== 'ProjectControls'
			})
			const rightSide =
				workerPool.data.filter((w: Employee) => {
					return w.role.hierarchyIndex === 2
				})?.flatMap(w => w.directReports ?? []) 

			return { availableWorkers: leftSide, assignedWorkers: rightSide }
		} else {
			const leftSide = workerPool.data.filter((w: Employee) => {
				return w.role.title !== oneRoleBelowUser.title
			})
			const rightSide = workerPool.data.filter((w: Employee) => {
				return w.role.title === oneRoleBelowUser.title
			})
			return { availableWorkers: leftSide, assignedWorkers: rightSide }
		}
	} catch (err) {
		const { eventId, message } = err as ThrownServiceError
		showErrorToast({
			eventId: eventId ?? '',
			traceId: 'projectService',
			message: message ?? '',
		})
		return { availableWorkers: [], assignedWorkers: [] }
	}
}

const getFormansOfProject = async () => {
	try{
		const result = await getAll<Employee[]>(`/Workers/Formans` )
		return result.data
	}catch(err){
		const { eventId, message } = err as ThrownServiceError
		showErrorToast({
			eventId: eventId ?? '',
			message: message ?? 'Could not retrieve formans for this project',
		})
		return null
	}
	
}

const AssignWorkersToForman = async (fromFormanId: number, toFormanId: number, workerIds: number[], passToGf: boolean) => {
	try{
		const result = await patch(`/Workers/${toFormanId}?passToGf=${passToGf}&fromFormanId=${fromFormanId}`, workerIds )
		return result.success
	}catch(err){
		const { eventId, message } = err as ThrownServiceError
		showErrorToast({
			eventId: eventId ?? '',
			message: message ?? `Could not assign workers to forman id ${toFormanId}`,
		})
		return false
	}
	
}

const getWorkerExtraInfo = async (workerId:number) => {
	try {
		const result = await getOne<Employee>(`/Workers/${workerId}/info`)
		return result.data
	} catch (err) {
		const { eventId, message } = err as ThrownServiceError
		showErrorToast({
			eventId: eventId ?? '',
			message: message ?? `Could not fetch worker's info: ${workerId}`,
		})
		return null
	}
}

const updateWorkerInfo = async (worker: Employee) => {
	try {
		const result = await put(`/Workers`, worker)
		return result.success
	} catch (err) {
		const { eventId, message } = err as ThrownServiceError
		showErrorToast({
			eventId: eventId ?? '',
			message: message ?? `Could not update ${worker.firstName} ${worker.lastName}'s info`,
		})
		return null
	}
}

export { unassignWorker, getAssignWorkersData, assignWorker, getFormansOfProject, AssignWorkersToForman, getWorkerExtraInfo, updateWorkerInfo }
