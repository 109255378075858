/* eslint-disable indent */
import dayjs from 'dayjs'

const formatPercent = (
	input?: number | string | undefined,
	minimumFractionDigits?: number,
): string => {
	if (input === undefined) return ''
	return input.toLocaleString(undefined, {
		style: 'percent',
		minimumFractionDigits: minimumFractionDigits ?? 2,
	})
}

const formatLocaleShortDate = (input: Date | string | null, ifUndefinedText?: string): string => {
	if (input instanceof Date) return input.toLocaleDateString('en-US')
	return input ? new Date(input).toLocaleDateString('en-US') : ifUndefinedText ?? 'n/a'
}

const formatLocaleShortDateTime = (
	input: Date | string | null | undefined,
	ifUndefinedText?: string,
): string => {
	return input
		? dayjs(input).utc(true).local().format('YYYY-MM-DD h:mm:ss A')
		: ifUndefinedText ?? 'n/a'
}

const formatLocaleTime = (input?: string, ifUndefinedText?: string): string => {
	return input
		? new Date(input).toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
		  })
		: ifUndefinedText ?? 'n/a'
}

const formatNumberAsLetter = (input: number | string) =>
	String.fromCharCode(64 + (typeof input === 'number' ? input : parseInt(input)))

const getColorLevel = (wbsIndex: number) => {
	const bit8 = 255 - (wbsIndex - 1) * 25
	return `rgba(${bit8},${bit8},${bit8}, .5)`
}

export {
	formatPercent,
	formatLocaleShortDate,
	getColorLevel,
	formatLocaleShortDateTime,
	formatNumberAsLetter,
	formatLocaleTime,
}
