import { Box, Toolbar } from '@mui/material'
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import BrockAppBar from '../navigation/BrockAppBar'
import BrockDrawer from '../navigation/BrockDrawer'

type LoggedInLayoutProps = {
	showDrawer?: boolean
}

const LoggedInLayout = ({ showDrawer = true }: LoggedInLayoutProps) => (
	<Box sx={{ height: '100vh', width: '100vw' }}>
		<BrockAppBar showAccountInfo={showDrawer} />
		{showDrawer && <BrockDrawer />}
		<Box
			component='main'
			sx={{
				paddingTop: '64px',
				left: '248px',
				top: '0px',
				position: 'relative',
				height: 'auto',
				width: 'calc(100vw - 248px)',
				overflowY: 'auto',
			}}
		>
			<Outlet />
		</Box>
	</Box>
)

export default LoggedInLayout
