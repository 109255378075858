import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Fullscreen } from '@mui/icons-material'

type ConfirmDialogProps = {
	title: string
	cancelButtonText?: string
	confirmButtonText: string
	children?: React.ReactNode
	visible: boolean
	onConfirm: () => void
	onCancel?: () => void
	canConfirm?: boolean
	fullScreen?: boolean
	childActions?: React.ReactNode
}

export default function ConfirmDialog({
	title,
	children,
	cancelButtonText,
	confirmButtonText,
	visible,
	onCancel,
	onConfirm,
	canConfirm,
	fullScreen = false,
	childActions
}: ConfirmDialogProps) {
	const handleConfirmClick = () => {
		if (onConfirm) onConfirm()
	}

	const handleCancelClick = () => {
		if (onCancel) onCancel()
	}

	return (
		<Dialog
			fullScreen={fullScreen}
			open={visible}
			onClose={handleCancelClick}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			maxWidth='xl'
		>
			<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description' component={'div'}>
					{children}
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>{childActions}</div>
				<div>
					{cancelButtonText && (
						<Button
							variant='contained'
							color='warning'
							onClick={handleCancelClick}
							style={{ marginRight: '10px' }}
						>
							{cancelButtonText}
						</Button>
					)}
					{confirmButtonText && (
						<Button
							variant='contained'
							disabled={canConfirm === undefined ? false : !canConfirm}
							color='success'
							onClick={handleConfirmClick}
							autoFocus
						>
							{confirmButtonText}
						</Button>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}
