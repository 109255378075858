import * as React from 'react'
import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { Constraint } from '../../types/Work/Constraint'
import { StyledTableCell } from '../core/StyledTableCell'
import { formatLocaleShortDateTime } from '../../services/DataFormatters'
import { AttachFile, Clear } from '@mui/icons-material'
import ConfirmDialog from '../core/ConfirmDialog'
import { useState } from 'react'
import { getConstraintAttachment } from '../../services/workItemService'

type ConstraintsListProps = {
	constraints: Constraint[] | undefined
	onConfirmClearConstraint: (constraint: Constraint) => void
}

export function ConstraintsList({ constraints, onConfirmClearConstraint }: ConstraintsListProps) {

	const [open, setOpen] = useState<boolean>(false)
	const [imageUri, setImageUri] = useState<string | undefined>(undefined)

	const showDialog = async (constraint: Constraint) => {
		const response = await getConstraintAttachment(constraint)
		setImageUri(response.uri + response.sasToken)
		setOpen(true)
	}
	function handleDialogClose(): void {
		setOpen(false)
		setImageUri(undefined)
	}


	return (
		<>
			<ConfirmDialog
				fullScreen={true}
				visible={open}
				title={'Constraint Attachment'}
				cancelButtonText={undefined}
				confirmButtonText='Close'
				onConfirm={handleDialogClose}
			>
				<Box sx={{ display: 'block', width: '100%', height: '100%' }}>
					{imageUri && <img src={imageUri} />}
				</Box>
			</ConfirmDialog>
			<TableContainer
				sx={{
					height: '100%',
					width: '100%',
					overflowX: 'scroll',
					overflowY: 'scroll',
				}}
			>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<StyledTableCell align='left'>Id</StyledTableCell>
							<StyledTableCell align='left'>Work Item Name</StyledTableCell>
							<StyledTableCell align='left'>Comment</StyledTableCell>
							<StyledTableCell align='center'>Reported By</StyledTableCell>
							<StyledTableCell align='center'>Reported At</StyledTableCell>
							<StyledTableCell align='center'>Cleared At</StyledTableCell>
							<StyledTableCell align='center'>Image</StyledTableCell>
							<StyledTableCell align='center'>Actions</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{(constraints ?? []).map((constraint: Constraint) => (
							<TableRow key={constraint.id}>
								<TableCell align='left'>{constraint.id}</TableCell>
								<TableCell align='left'>{constraint.workItemName}</TableCell>
								<TableCell align='left'>{constraint.comments}</TableCell>
								<TableCell align='center'>{constraint.reportedByName}</TableCell>
								<TableCell align='center'>
									{formatLocaleShortDateTime(constraint.timeReported)}
								</TableCell>
								<TableCell align='center'>
									{formatLocaleShortDateTime(constraint.timeCleared)}
								</TableCell>
								<TableCell align='center'>
									{constraint.attachments.length > 0 && (
										<IconButton onClick={() => showDialog(constraint)} color='info'>
											<AttachFile
												sx={{
													color: '#FFFFFF',
													background: '#0DAEFF',
													padding: '2px',
													borderRadius: '4px',
												}}
											/>
										</IconButton>
									)}
								</TableCell>
								<TableCell align='center'>
									{!constraint.timeCleared && (
										<IconButton color='error' onClick={() => onConfirmClearConstraint(constraint)}>
											<Clear />
										</IconButton>
									)}
								</TableCell>
							</TableRow>
						))}
						{(constraints?.length ?? 0) === 0 && (
							<TableRow>
								<TableCell align='center' scope='row' colSpan={17}>
									<h4 style={{ fontStyle: 'italic' }}>No Constraints were found</h4>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
