import { ErrorToast } from './ErrorToast'
import { toast } from 'react-toastify'
import { ErrorToastData } from '../types/Core/ErrorToastData'
import { useEnvironmentVariables } from '../components/hooks/useEnvironmentVariables'

const showErrorToast = (data: ErrorToastData) => {
	const { showErrorToasts } = useEnvironmentVariables()
	if (!showErrorToasts) return

	toast.error<ErrorToastData>(ErrorToast, {
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
		data: {
			message: data.message,
			traceId: data.traceId,
			eventId: data.eventId,
		},
	})
}

const showSuccessToast = (message: string) => {
	const { showSuccessToasts } = useEnvironmentVariables()
	if (!showSuccessToasts) return
	toast.success(message, {
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	})
}

export { showErrorToast, showSuccessToast }
