import { TableCell, tableCellClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#F7F7F7',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}))
