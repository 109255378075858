import { Employee } from '../types/Employee/Employee'
import { WorkItemInfo } from '../types/Work/WorkItemInfo'
import { getAll, post, deleteOne, getOne } from './httpService'

const getForemen = async (includeDirectReports: boolean, id: number): Promise<Employee[]> => {
	const result = await getAll<Employee[]>(
		`/Workers?reportsTo=${id}&includeChildren=${includeDirectReports}`,
		'Failed to get Employees',
	)

	return searchBranch(result?.data ?? [])
}

function searchBranch(employee: Employee[]): Employee[] {
	if (!employee) {
		return []
	}
	return employee?.reduce((acc: Employee[], child: Employee) => {
		if (child.role.title === 'Foreman') {
			acc.push(child)
		}
		if (child.directReports && child?.directReports?.length > 0) {
			acc.push(...searchBranch(child?.directReports))
		}
		return acc
	}, [])
}

const getForemanWork = async (
	id: number,
): Promise<{ employee: Employee; directReports: Employee[] }> => {
	const result = await getAll<{ employee: Employee; directReports: Employee[] }>(
		`/Workers/${id}/WorkItems?direcReportWorkItems=true&includeTasks=true`,
	)

	return result?.data ?? []
}

const assignTask = async (taskId: number, workerId: number) => {
	const result = await post(
		`/Workers/${workerId}/Tasks/${taskId}`,
		`Could not assign task ${taskId} to worker ${workerId}.`,
	)
	return result.success
}

const unassignTask = async (taskId: number, workerId: number) => {
	const result = await deleteOne(
		`/Workers/${workerId}/Tasks/${taskId}`,
		`Could not unassign task ${taskId} from worker ${workerId}.`,
	)
	return result.success
}

const activateTaskStatus = async (workerId: number, taskId: number) => {
	const result = await post(
		`/Workers/${workerId}/ActiveTasks/${taskId}`,
		`Could not activate task ${taskId} for worker ${workerId}.`,
	)
	return result.success
}

const deactivateTaskStatus = async (workerId: number, taskId: number) => {
	const result = await deleteOne(
		`/Workers/${workerId}/ActiveTasks/${taskId}`,
		`Could not activate task ${taskId} for worker ${workerId}.`,
	)
	return result.success
}

const getWorkItemInfo = async (workerId: number, workItemIds: number[]) => {
	const result = await getAll<WorkItemInfo[]>(
		`/Workers/${workerId}/WorkItems/Info?workItemIds=${workItemIds.join(",")}`,
	)

	return result?.data ?? []
}

export {
	getForemen,
	getForemanWork,
	assignTask,
	unassignTask,
	activateTaskStatus,
	deactivateTaskStatus,
	getWorkItemInfo
}
