import { useNavigate } from 'react-router-dom'
import { useDecodeToken } from './useDecodeToken'
import { TokenStorageLocation } from './useDecodeToken'

type Auth = {
	token: string | null
	hasWpaToken: boolean
	removeWpaToken: () => void
}

const useWpaToken = (): Auth => {
	const token = localStorage.getItem(TokenStorageLocation.WpaTokenStorage)
	const decodedToken = useDecodeToken()

	const hasValidToken = (): boolean => {
		const jsTime = new Date().getTime() / 1000
		const isFromIssuer = decodedToken?.iss === 'Brock' ? true : false
		const isNotExpired = decodedToken?.exp && decodedToken.exp >= jsTime ? true : false
		const isNotBefore = decodedToken?.nbf && decodedToken.nbf <= jsTime ? true : false

		return isFromIssuer && isNotExpired && isNotBefore
	}

	return {
		token: hasValidToken() ? token : null,
		hasWpaToken: hasValidToken(),
		removeWpaToken: () => {
			localStorage.removeItem(TokenStorageLocation.WpaTokenStorage)
			window.location.replace('/?deleted=true')
		},
	}
}

export const useRBAC = (roles?: ReadonlyArray<string>): boolean | null => {
	const decoded = useDecodeToken()
	return roles && decoded && decoded.role
		? roles.some((role) => decoded.role.includes(role))
		: false
}

export default useWpaToken
