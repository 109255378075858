import React, { useState, useEffect } from 'react'
import '../../pages/styles.scss'

type AnimatedNumberFieldProps = {
	value?: number | string
	formatter?: (input?: number | string | undefined) => string
}

const AnimatedNumberField = ({ value, formatter }: AnimatedNumberFieldProps) => {
	const [localValue, setLocalValue] = useState<string>(value?.toString() ?? '')
	const [isFirstTime, setFirstTime] = useState(true)
	const [className, setClassName] = useState<string>('static-number-field')

	useEffect(() => {
		if (formatter) setLocalValue(formatter(value))
		else setLocalValue(value?.toString() ?? '')
		if (!isFirstTime) {
			setClassName('static-number-field-animated')
			window.setTimeout(() => {
				setClassName('static-number-field')
			}, 1000)
		}
		setFirstTime(false)
	}, [value])

	return (
		<>
			<span className={className}>{localValue}</span>
		</>
	)
}

export default AnimatedNumberField
