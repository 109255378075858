/* eslint-disable react/no-unescaped-entities */
import * as React from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { UnauthenticatedPage } from './security/unAuthenticatedPage'
import useWpaToken from './security/useWpaToken'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import BrockRoutes from './components/navigation/BrockRoutes'
import BrockLimitedRoutes from './components/navigation/BrockLimitedRoutes'

export default function App() {
	const { hasWpaToken } = useWpaToken()

	return (
		<>
			<AuthenticatedTemplate>
				{/* Save this code please, ill need it very soon */}
				{/* {hasWpaToken ? <BrockRoutes /> : <UnauthorizedPage />} */}
				{hasWpaToken ? <BrockRoutes /> : <BrockLimitedRoutes />}
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<UnauthenticatedPage />
			</UnauthenticatedTemplate>
			<ToastContainer
				position='bottom-right'
				autoClose={2500}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='colored'
			/>
		</>
	)
}
