import { useEnvironmentVariables } from '../components/hooks/useEnvironmentVariables'

export const msalConfig = () => {
	const { redirectUri } = useEnvironmentVariables()
	return {
		auth: {
			clientId: 'ece179cb-e1bb-4ffd-904b-1292907abff5',
			authority: 'https://login.microsoftonline.com/ff348a22-fd20-4491-af39-832e7b76633f',
			redirectUri: redirectUri,
		},
		cache: {
			cacheLocation: 'sessionStorage', // This configures where your cache will be stored
			storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
		},
	}
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ['api://2f805206-ec8b-4c16-a2e0-d1023f74650c/WPAAPIReadandVerify'],
}
