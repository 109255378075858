/* eslint-disable react/prop-types */
import * as React from 'react'
import { ToastContentProps } from 'react-toastify/dist/types'
import { ErrorToastData } from '../types/Core/ErrorToastData'

const ErrorToast = (props: ToastContentProps<ErrorToastData>) => {
	const href = `mailto:?subject=Error%20Report&body=Error ID=${
		props.data?.eventId ?? 'n/a'
	}%20Error%20Code%3D${props.data?.traceId ?? 'n/a'}`
	return (
		<a style={{ color: '#fff', textDecoration: 'none' }} href={href}>
			{props.data?.message ?? 'No error given'}
		</a>
	)
}

export { ErrorToast }
