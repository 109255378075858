/* eslint-disable react/react-in-jsx-scope */
import AssignmentIcon from '@mui/icons-material/Assignment'
import PostAddIcon from '@mui/icons-material/PostAdd'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined'
import WorkItemsPage from '../../pages/WorkItemsPage'
import LaborPage from '../../pages/LaborPage'
import ProjectsPage from '../../pages/ProjectsPage'
import WorkItemsOverviewPage from '../../pages/WorkItemsOverviewPage'
import { JobRoleEnum } from '../../types/Employee/JobRole'
import LockIcon from '@mui/icons-material/Lock'

type RouteDataItem = {
	title: string
	routesPath: string
	path: string
	icon: any
	defaultRoute: boolean
	roles: JobRoleEnum[]
}
export const routeData: RouteDataItem[] = [
	{
		title: 'Projects',
		routesPath: 'projects',
		path: '/projects/details',
		icon: <PostAddIcon />,
		defaultRoute: false,
		roles: ['ProjectControls', 'SuperAdmin'],
	},
	{
		title: 'Backlog',
		path: '/backlog',
		routesPath: 'backlog/*',
		icon: <AssignmentIcon />,
		defaultRoute: true,
		roles: ['ProjectControls', 'SuperAdmin', 'Foreman'],
	},
	{
		title: 'Assign Work',
		routesPath: 'workitems/*',
		path: '/workitems',
		icon: <PostAddIcon />,
		defaultRoute: false,
		roles: ['ProjectControls', 'Superintendent', 'SuperAdmin', 'GeneralForeman'],
	},
	{
		title: 'Labor Assignments',
		routesPath: 'labor/*',
		path: '/labor',
		icon: <i className='material-icons account_tree'>&#xe97a;</i>,
		defaultRoute: false,
		roles: ['ProjectControls', 'SuperAdmin'],
	},
	{
		title: 'Work Items Overview',
		routesPath: 'workitems/overview/*',
		path: '/workitems/overview',
		icon: <FormatListBulletedIcon />,
		defaultRoute: false,
		roles: ['ProjectControls', 'Superintendent', 'SuperAdmin', 'GeneralForeman'],
	},
	{
		title: 'Shift End Report',
		routesPath: 'reports/*',
		path: '/reports',
		icon: <AssessmentIcon />,
		defaultRoute: true,
		roles: ['ProjectControls', 'Superintendent', 'SuperAdmin', 'GeneralForeman', 'Foreman'],
	},
	{
		title: 'Constraints',
		routesPath: 'constraints/*',
		path: '/constraints',
		icon: <LockIcon />,
		defaultRoute: true,
		roles: ['ProjectControls', 'Superintendent', 'SuperAdmin', 'GeneralForeman'],
	},
]
