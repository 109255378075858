import * as React from 'react'
import { Drawer, Toolbar } from '@mui/material'
import BrockSideBar from './BrockSideBar'

const drawerWidth = 248

export default function BrockAppBar() {
	return (
		<Drawer
			variant='permanent'
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box' },
			}}
		>
			<Toolbar />
			<BrockSideBar />
		</Drawer>
	)
}
