import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './authConfig'

export const UnauthenticatedPage = () => {
	const { instance } = useMsal()

	instance.loginRedirect(loginRequest).catch((e) => {
		console.log(e)
	})

	return <div>Redirect for Login.....</div>
}
