import React, { createContext, useContext, useState } from 'react'
import { showErrorToast, showSuccessToast } from '../../services/alertServices'
import { getAssignWorkersData, unassignWorker, assignWorker } from '../../services/laborService'
import { ThrownServiceError } from '../../types/Core/ThrownServiceError'
import { Employee } from '../../types/Employee/Employee'

interface LaborContextType {
	availableEmployees: Employee[] | undefined
	assignedEmployees: Employee[] | undefined
	unassignEmployee: (employee: Employee) => void
	assignEmployee: (employeeFrom: Employee, employeeTo?: Employee) => void
	loadData: () => Promise<void>
}

const LaborContext = createContext<LaborContextType | null>(null)

const useLaborContext = (): LaborContextType => {
	const laborContextState = useContext(LaborContext)
	if (laborContextState === null) {
		throw new Error(
			'LaborContext not found. Try wrapping a parent component with <LaborContext.Provider>.',
		)
	}
	return laborContextState
}

type LaborProviderProps = {
	children?: React.ReactNode
}

const LaborProvider = ({ children }: LaborProviderProps) => {
	const [availableEmployees, setAvailableEmployees] = useState<Employee[] | undefined>()
	const [assignedEmployees, setAssignedEmployees] = useState<Employee[] | undefined>()

	const unassignEmployee = async (employee: Employee) => {
		await unassignWorker(employee).then(() => {
			showSuccessToast(`${employee.firstName} ${employee.lastName} was unassigned.`)
		})
		await loadData()
	}

	const loadData = async () => {
		const { availableWorkers, assignedWorkers } = await getAssignWorkersData()
		setAvailableEmployees(availableWorkers)
		setAssignedEmployees(assignedWorkers)
	}

	const assignEmployee = async (employeeFrom: Employee, employeeTo?: Employee) => {
		await assignWorker(employeeFrom, employeeTo)
			.then(async () => {
				showSuccessToast(
					`${employeeFrom.firstName} ${employeeFrom.lastName} was assigned to ${
						employeeTo ? `${employeeTo.firstName} ${employeeTo.lastName}` : 'undefined'
					}`,
				)
				await loadData()
			})
			.catch((err) => {
				const { eventId, message } = err as ThrownServiceError
				showErrorToast({
					eventId: eventId ?? '',
					traceId: 'projectService',
					message: message ?? '',
				})
			})
	}
	return (
		<LaborContext.Provider
			value={{
				availableEmployees,
				assignedEmployees,
				assignEmployee,
				unassignEmployee,
				loadData,
			}}
		>
			{children}
		</LaborContext.Provider>
	)
}

export { useLaborContext, LaborProvider }
