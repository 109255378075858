import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
// import Home from '../../pages/Home'
import NoMatch from '../../pages/NotFound'
import { useTokenClaims } from '../../security/useTokenClaims'
import { LaborProvider } from '../labor/LaborContext'
import LoggedInLayout from '../layout/LoggedInLayout'
import { ProjectProvider } from '../project/contexts/ProjectContext'
import { ShiftEndProvider } from '../reports/shift-end/ShiftEndContext'
import ShiftEndReportPage from '../../pages/ShiftEndReportPage'
import ShiftEndSelectForemanSelectionPage from '../../pages/ShiftEndSelectForemanPage'
import ShiftEndWorkItemsPartialPage from '../../pages/ShiftEndWorkItemsPartialPage'
import ShiftEndWorkersPartialPage from '../../pages/ShiftEndWorkersPartialPage'
import ConstraintsPage from '../../pages/ConstraintsPage'

const ForemanSelectionPage = React.lazy(() => import('../../pages/ForemanSelectionPage'))
const ForemanDetailPage = React.lazy(() => import('../../pages/ForemanDetailPage'))
const WorkItemsPage = React.lazy(() => import('../../pages/WorkItemsPage'))
const LaborPage = React.lazy(() => import('../../pages/LaborPage'))
const ProjectsPage = React.lazy(() => import('../../pages/ProjectsPage'))
const ProjectDetailsPage = React.lazy(() => import('../../pages/ProjectDetailsPage'))
const ProjectFilesPage = React.lazy(() => import('../../pages/ProjectFilesPage'))
const ProjectExportsPage = React.lazy(() => import('../../pages/ProjectExportsPage'))
const ImportFileDetailsPage = React.lazy(() => import('../../pages/ImportFileDetailsPage'))
const ProjectsListPage = React.lazy(() => import('../../pages/ProjectsListPage'))
const ProjectNewPage = React.lazy(() => import('../../pages/ProjectNewPage'))
const WorkItemsOverviewPage = React.lazy(() => import('../../pages/WorkItemsOverviewPage'))

export default function BrockRoutes() {
	const { role } = useTokenClaims()
	return (
		<ShiftEndProvider>
			<Routes>
				<Route path='/' element={<LoggedInLayout />}>
					{/* Restrict access to assign work page to General Foreman and above */}
					{role.title !== 'Laborer' && role.title !== 'Foreman' ? (
						<Route
							index
							element={
								<React.Suspense fallback={<>...</>}>
									<WorkItemsPage />
								</React.Suspense>
							}
						/>
					) : (
						<Route
							index
							element={
								<React.Suspense fallback={<>...</>}>
									<ForemanDetailPage />
								</React.Suspense>
							}
						/>
					)}
					{role.title === 'ProjectControls' && (
						// Backlog page
						<Route path='backlog/*'>
							<Route
								index
								element={
									<React.Suspense fallback={<>...</>}>
										<ForemanSelectionPage />
									</React.Suspense>
								}
							/>
							<Route
								path=':id'
								element={
									<React.Suspense fallback={<>...</>}>
										<ForemanDetailPage />
									</React.Suspense>
								}
							/>
						</Route>
					)}
					{role.title === 'Foreman' && (
						// Backlog page
						<Route
							index
							path='backlog'
							element={
								<React.Suspense fallback={<>...</>}>
									<ForemanDetailPage />
								</React.Suspense>
							}
						/>
					)}
					{/* Projects pages */}

					{role.title === 'ProjectControls' && <Route path='projects'>
						<Route
							index
							element={
								<React.Suspense fallback={<>...</>}>
									<ProjectsListPage />
								</React.Suspense>
							}
						/>
						<Route
							path='new'
							element={
								<React.Suspense fallback={<>...</>}>
									<ProjectNewPage />
								</React.Suspense>
							}
						/>
						<Route
							path=':id'
							element={
								<React.Suspense fallback={<>...</>}>
									<ProjectProvider>
										<ProjectsPage />
									</ProjectProvider>
								</React.Suspense>
							}
						>
							<Route
								path='details'
								element={
									<React.Suspense fallback={<>...</>}>
										<ProjectDetailsPage />
									</React.Suspense>
								}
							/>
							<Route
								path='files'
								element={
									<React.Suspense fallback={<>...</>}>
										<ProjectFilesPage />
									</React.Suspense>
								}
							/>
							<Route
								path='files/:id'
								element={
									<React.Suspense fallback={<>...</>}>
										<ImportFileDetailsPage />
									</React.Suspense>
								}
							/>
							<Route
								path='exports'
								element={
									<React.Suspense fallback={<>...</>}>
										<ProjectExportsPage />
									</React.Suspense>
								}
							/>
						</Route>
					</Route>}
					{/* Restrict access to assign work page to General Foreman and above */}
					{role.title !== 'Laborer' && role.title !== 'Foreman' && (
						// Assign Work page
						<Route path='workitems/*'>
							<Route
								path=':id'
								element={
									<React.Suspense fallback={<>...</>}>
										<WorkItemsPage />
									</React.Suspense>
								}
							/>
							<Route
								index
								element={
									<React.Suspense fallback={<>...</>}>
										<WorkItemsPage />
									</React.Suspense>
								}
							/>
							{/* Work Item Overview page */}
							<Route
								path='overview'
								element={
									<React.Suspense fallback={<>...</>}>
										<WorkItemsOverviewPage />
									</React.Suspense>
								}
							/>
						</Route>
					)}
					
					{role.title === 'ProjectControls' && (
						<Route path='labor/*'>
							<Route
								index
								element={
									<React.Suspense fallback={<>...</>}>
										<LaborProvider>
											<LaborPage />
										</LaborProvider>
									</React.Suspense>
								}
							/>
						</Route>
					)}

					{/* Restrict access to assign work page to General Foreman and above */}
					{role.title !== 'Laborer' && role.title !== 'Foreman' && (
						// Shift End Report page
						<Route path='reports/*'>
							<Route index element={<ShiftEndSelectForemanSelectionPage />} />
							<Route path=':id' element={<ShiftEndReportPage />}>
								<Route path='workers' element={<ShiftEndWorkersPartialPage />} />
								<Route path='workitems' element={<ShiftEndWorkItemsPartialPage />} />
							</Route>
						</Route>
					)}
					{role.title === 'Foreman' && (
						// Shift End Report page
						<Route path='reports/*' element={<ShiftEndReportPage />}>
							<Route index path='workers' element={<ShiftEndWorkersPartialPage />} />
							<Route path='workitems' element={<ShiftEndWorkItemsPartialPage />} />
						</Route>
					)}
					{(role.title === 'ProjectControls' ||
						role.title === 'Superintendent' ||
						role.title === 'GeneralForeman' ||
						role.title === 'Foreman') && (
						// Shift End Report page
						<Route path='constraints/*'>
							<Route index element={<ConstraintsPage />} />
						</Route>
					)}
					<Route path='*' element={<NoMatch />} />
				</Route>
			</Routes>
		</ShiftEndProvider>
	)
}
