import * as React from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
    IconButton,
} from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


type RemoveUnproductiveDialogProps = {
    onConfirm: () => void
}

const RemoveUnproductiveDialog = ({onConfirm}: RemoveUnproductiveDialogProps) => {
	const [open, setOpen] = React.useState(false)

	return (
		<>
			<IconButton color='error' onClick={() => setOpen(true)}>
				<RemoveCircleIcon />
			</IconButton>
			<Dialog open={open} maxWidth='xl' onClose={() => setOpen(false)}>
				<DialogTitle id='alert-dialog-title'>Deactive Task?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to remove this unproductive time record?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color='warning' variant='contained' onClick={() => setOpen(false)}>
						No
					</Button>
					<Button
						color='success'
						variant='contained'
						onClick={() => {
							onConfirm()
							setOpen(false)
						}}
					>
						Yes, I am sure
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default RemoveUnproductiveDialog
