import * as React from 'react'
import {
	Box,
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ThemeProvider,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { routeData } from './RouteData'
import { getSideNavBarTheme } from '../../themes'
import ProjectNavigationDropdown from '../core/ProjectNavigationDropdown'
import { useDecodeToken } from '../../security/useDecodeToken'

export default function BrockAppBar() {
	const token = useDecodeToken()
	const filterRole = token?.role ?? 'Laborer' // least priveleged

	const navigate = useNavigate()
	return (
		<Box sx={{ overflow: 'auto' }}>
			<ThemeProvider theme={getSideNavBarTheme}>
				<List>
					<>
						{routeData
							.filter((x) => x.roles.includes(filterRole))
							.map((routeInfo) => (
								<ListItem key={routeInfo.title} sx={{ display: 'contents' }}>
									{routeInfo.title !== 'Projects' && (
										<ListItemButton sx={{ width: '100%' }} onClick={() => navigate(routeInfo.path)}>
											<ListItemIcon>{routeInfo.icon}</ListItemIcon>
											<ListItemText primary={routeInfo.title} />
										</ListItemButton>
									)}
									<ProjectNavigationDropdown routeInfo={routeInfo}></ProjectNavigationDropdown>
								</ListItem>
							))}
					</>
				</List>
			</ThemeProvider>
		</Box>
	)
}
