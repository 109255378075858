import { JobRole, JobRoleEnum } from '../types/Employee/JobRole'
import { useDecodeToken } from './useDecodeToken'

type TokenClaims = {
	id: number
	projectId: number
	firstName: string
	lastName: string
	role: JobRole
}

//Hardcoding token cliams for now for testing purposes
export const useTokenClaims = (): TokenClaims => {
	const decoded = useDecodeToken()
	return {
		id: Number(decoded?.nameid) ?? 1,
		projectId: Number(decoded?.projectId) ?? 0,
		firstName: decoded?.given_name ?? 'null',
		lastName: decoded?.family_name ?? 'null',
		role: {
			title: decoded?.role as JobRoleEnum,
			hierarchyIndex: parseInt(decoded?.role_index ?? '0'),
		},
	}
}
