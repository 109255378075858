import * as React from 'react'
import { useEffect, useState } from 'react'
import { ConstraintsList } from '../components/constraints/ConstraintsList'
import { clearConstraint, getAllConstraints } from '../services/workItemService'
import { Constraint } from '../types/Work/Constraint'
import SearchBar from '../components/workitemsoverview/SearchBar'
import dayjs from 'dayjs'
import { Box, Typography } from '@mui/material'
import { formatLocaleShortDateTime } from '../services/DataFormatters'
import ConfirmDialog from '../components/core/ConfirmDialog'
import { useLocation } from 'react-router-dom'

export default function ConstraintsPage() {
	const location = useLocation()
	const { searchTextProp = '' } = location.state || {}
	console.log(searchTextProp)
	const [constraints, setConstraints] = useState<Constraint[] | undefined>()
	const [searchText, setSearchText] = useState<string>(searchTextProp)
	const [filtererConstraints, setFilteredConstraints] = useState<Constraint[] | undefined>()
	const [isConfirmClearConstraintDialogVisible, showConfirmClearDialog] = useState<boolean>(false)
	const [confirmConstraint, setConfirmConstraint] = useState<Constraint>()

	const loadData = async () => {
		const constraints = await getAllConstraints()
		// Sort by 'timeReported' (descending)
		constraints.sort((a, b) => {
			const dateA = new Date(a.timeReported);
    		const dateB = new Date(b.timeReported);
			return dateB.getTime() - dateA.getTime();
		});
		setConstraints(constraints)
	}

	React.useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		if (searchText === '') setFilteredConstraints(constraints)
		else {
			const filtered = (constraints || []).filter(
				(x) =>
					x.workItemName.toLowerCase().includes(searchText.toLowerCase()) ||
					x.comments.toLowerCase().includes(searchText.toLowerCase()) ||
					x.reportedByName.toLowerCase().includes(searchText.toLowerCase()) ||
					(x.timeCleared
						? formatLocaleShortDateTime(x.timeCleared).includes(searchText.toLowerCase())
						: false) ||
					(x.timeReported
						? formatLocaleShortDateTime(x.timeReported).includes(searchText.toLowerCase())
						: false),
			)

			setFilteredConstraints(filtered)
		}
	}, [constraints, searchText])

	const handleOnSearchChange = (searchText: string) => {
		setSearchText(searchText)
	}

	const handleConfirmClearConstraint = (constraint: Constraint) => {
		setConfirmConstraint(constraint)
		showConfirmClearDialog(true)
	}

	return (
		<div className='page-layout'>
			<Box>
				<Typography
					sx={{
						fontSize: '1.5rem',
						paddingLeft: '1rem',
						paddingTop: '.5rem',
						zIndex: '3',
					}}
				>
					Constraints
				</Typography>
				<SearchBar
					onChange={handleOnSearchChange}
					showIncludeFilter={false}
					searchPlaceholder='Filter Constraints'
					searchText={searchText}
				/>
			</Box>
			<ConstraintsList
				constraints={filtererConstraints}
				onConfirmClearConstraint={handleConfirmClearConstraint}
			/>
			<ConfirmDialog
				visible={isConfirmClearConstraintDialogVisible}
				title={'Confirm Clear Constraint'}
				cancelButtonText='No, do not clear this constraint.'
				confirmButtonText='Yes, clear this constraint.'
				onCancel={() => {
					showConfirmClearDialog(false)
				}}
				onConfirm={async () => {
					if (confirmConstraint) await clearConstraint(confirmConstraint)
					showConfirmClearDialog(false)
					await loadData()
				}}
			>
				<p>
					You are about to clear the constraint for `{confirmConstraint?.workItemName}` reported at{' '}
					{formatLocaleShortDateTime(confirmConstraint?.timeReported)}.
					<br />
					Are you sure you wish to continue?
				</p>
			</ConfirmDialog>
		</div>
	)
}
