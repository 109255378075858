import jwt_decode, { JwtPayload } from 'jwt-decode'
import { JobRoleEnum } from '../types/Employee/JobRole'

export enum TokenStorageLocation {
	WpaTokenStorage = 'wpa-token-storage',
}

export type JwtToken = {
	nameid: string
	AadOid: string
	email: string
	projectId: string
	streamUrl: string
	given_name: string
	family_name: string
	role: JobRoleEnum
	role_index: string
} & JwtPayload

export const useDecodeToken = (): JwtToken | null => {
	const token = localStorage.getItem(TokenStorageLocation.WpaTokenStorage)
	const decoded = token ? jwt_decode<JwtToken>(token) : null
	return decoded
}
