import { Typography } from '@mui/material'
import * as React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs()

	return (
		<div style={{ marginLeft: '32px', marginTop: '10px', marginBottom: '0px' }}>
			{breadcrumbs.map(({ breadcrumb }, index) => {
				return (
					<Typography
						key={index}
						variant='body1'
						component={'span'}
						sx={(theme) => ({ color: '#858585', fontWeight: 500, fontSize: '14px' })}
					>
						{index > 0 && (
							<div style={{ display: 'inline-block', textAlign: 'center', width: '30px' }}>
								&gt;
							</div>
						)}
						{breadcrumb}
					</Typography>
				)
			})}
		</div>
	)
}

export default Breadcrumbs
