import React, { createContext, useContext, useState } from 'react'
import {
	getForemanWork,
	unassignTask as unassignTaskService,
	assignTask as assignTaskService,
	activateTaskStatus,
	deactivateTaskStatus,
	getWorkItemInfo,
} from '../../services/backlogService'
import { Employee } from '../../types/Employee/Employee'
import { WorkItemTask } from '../../types/Project/WorkItemTask'
import { WorkItemInfo } from '../../types/Work/WorkItemInfo'
import { WorkItem } from '../../types/Work/WorkItem'
interface BacklogContextType {
	employee: Employee | undefined
	directReports: Employee[] | undefined
	unassignTask: (task: WorkItemTask, employee: Employee) => Promise<boolean>
	assignTask: (task: WorkItemTask, employee: Employee) => Promise<boolean | 'duplicate'>
	loadData: (id?: number) => Promise<void>
	setTaskStatus: (changeToActive: boolean, workerId: number, taskId: number) => Promise<boolean>
	getWorkItemPaths: (workItemId: number[]) => Promise<WorkItemInfo[]>
}

const BacklogContext = createContext<BacklogContextType | null>(null)

const useBacklogContext = (): BacklogContextType => {
	const backlogContextState = useContext(BacklogContext)
	if (backlogContextState === null) {
		throw new Error(
			'BacklogContext not found. Try wrapping a parent component with <BacklogContext.Provider>.',
		)
	}
	return backlogContextState
}

type BacklogProviderProps = {
	children?: React.ReactNode
}

const BacklogProvider = ({ children }: BacklogProviderProps) => {
	const [foremanId, setForemanId] = useState<number | undefined>()
	const [employee, setEmployee] = useState<Employee | undefined>()
	const [directReports, setDirectReports] = useState<Employee[] | undefined>([])
	const [workItemHierarchies, setWorkItemHierarchies] = useState<WorkItemInfo[]>([])

	const setTaskStatus = async (changeStatusTo: boolean, workerId: number, taskId: number) => {
		let result: boolean
		if (changeStatusTo) result = await activateTaskStatus(workerId, taskId)
		else result = await deactivateTaskStatus(workerId, taskId)
		await loadData()
		return result
	}

	const unassignTask = async (task: WorkItemTask, employee: Employee) => {
		const result = await unassignTaskService(task.id, employee.id)
		await loadData()
		return result
	}

	const assignTask = async (
		task: WorkItemTask,
		employee: Employee,
	): Promise<boolean | 'duplicate'> => {

		const result = await assignTaskService(task.id, employee.id)
		await loadData()
		return result
	}

	const loadData = async (id?: number) => {
		if (id) {
			setForemanId(id)
			const { employee, directReports } = await getForemanWork(id)
			setEmployee(employee)
			setDirectReports(directReports)
			const lastLayerWorkItemsIds = getLastLayerWorkItems(directReports).map(wi => wi.id);
			if(lastLayerWorkItemsIds.length === 0) return
			// const wiHierarchies = (await getWorkItemInfo(id, Array.from(new Set(lastLayerWorkItemsIds)))) as WorkItemInfo[]
			// setWorkItemHierarchies(wiHierarchies)
		} else if (foremanId) {
			const { employee, directReports } = await getForemanWork(foremanId)
			setEmployee(employee)
			setDirectReports(directReports)
			const lastLayerWorkItemsIds = getLastLayerWorkItems(directReports).map(wi => wi.id);
			if(lastLayerWorkItemsIds.length === 0) return
			// const wiHierarchies = (await getWorkItemInfo(foremanId, Array.from(new Set(lastLayerWorkItemsIds)))) as WorkItemInfo[]
			// setWorkItemHierarchies(wiHierarchies)
		}

	}
	function getLastLayerWorkItems(directReports: Employee[]): WorkItem[] {
		const lastLayerWorkItems: WorkItem[] = [];
	
		function findLeafWorkItems(workItems?: WorkItem[]) {
			if (!workItems) return;
	
			for (const workItem of workItems) {
				if (!workItem.childrenWorkItems || workItem.childrenWorkItems.length === 0) {
					lastLayerWorkItems.push(workItem);
				} else {
					findLeafWorkItems(workItem.childrenWorkItems);
				}
			}
		}
		for (const employee of directReports) {
			if (employee.workItems) {
				findLeafWorkItems(employee.workItems);
			}
		}
		return lastLayerWorkItems;
	}

	async function getWorkItemPaths(workItemIds: number[]) {

		const wiHierarchy = (await getWorkItemInfo(foremanId!, workItemIds)) as WorkItemInfo[]
		return wiHierarchy

	}

	return (
		<BacklogContext.Provider
			value={{ employee, directReports, unassignTask, assignTask, loadData, setTaskStatus, getWorkItemPaths }}
		>
			{children}
		</BacklogContext.Provider>
	)
}

export { useBacklogContext, BacklogProvider }
