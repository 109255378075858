import React, { createContext, useContext, useState } from 'react'
import { ProjectInfo } from '../../../types/Project/ProjectInfo'
import {
	getAvailableCrafts,
	getProjectInfo,
	activateProject as activateProjectService,
} from '../../../services/projectService'
import { Craft } from '../../../types/Project/Craft'
import { JobRole } from '../../../types/Employee/JobRole'
import { getRoles } from '../../../services/rolesService'
import { ApiBaseResponse } from '../../../services/httpService'

interface ProjectContextType {
	projectInfo: ProjectInfo | undefined
	projectCrafts: Craft[] | undefined
	roles: JobRole[] | undefined
	isProjectSaving: boolean
	isProjectLoaded: boolean
	setProjectSaving: () => void
	loadProject: () => Promise<void>
	activateProject: () => Promise<ApiBaseResponse>
}

const ProjectContext = createContext<ProjectContextType | null>(null)

const useProjectContext = (): ProjectContextType => {
	const projectContextState = useContext(ProjectContext)
	if (projectContextState === null) {
		throw new Error(
			'ProjectContext not found. Try wrapping a parent component with <ProjectContext.Provider>.',
		)
	}
	return projectContextState
}

type ProjectProviderProps = {
	children?: React.ReactNode
}

const ProjectProvider = ({ children }: ProjectProviderProps) => {
	const [projectInfo, setProjectInfo] = useState<ProjectInfo | undefined>()
	const [projectCrafts, setProjectCrafts] = useState<Craft[] | undefined>()
	const [roles, setRoles] = useState<JobRole[] | undefined>()
	const [isProjectSaving, setIsProjectSaving] = useState<boolean>(false)
	const [isProjectLoaded, setIsProjectLoaded] = useState<boolean>(false)

	const loadProject = async () => {
		await getProjectInfo().then(async (projectInfo) => {
			setProjectInfo(projectInfo)
			setIsProjectLoaded(true)
			
			await getAvailableCrafts().then((crafts) => {
				setProjectCrafts(crafts.filter((x) => projectInfo.crafts.includes(x.id)))
			})
		})
		await getRoles().then((roles: JobRole[]) => {
			setRoles(roles)
		})
	}

	const activateProject = async (): Promise<ApiBaseResponse> => {
		return await activateProjectService()
	}

	const setProjectSaving = () => {
		setIsProjectSaving(true)
		window.setTimeout(() => {
			setIsProjectSaving(false)
		}, 1000)
	}

	return (
		<ProjectContext.Provider
			value={{
				projectInfo,
				projectCrafts,
				roles,
				isProjectLoaded,
				isProjectSaving,
				setProjectSaving,
				loadProject,
				activateProject,
			}}
		>
			{children}
		</ProjectContext.Provider>
	)
}

export { useProjectContext, ProjectProvider }
